<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model.trim='filters.keyWord' placeholder='请输入姓名/手机' clearable @clear='getStudentLearningAccountListPage' @input='() =>(filters.keyWord = filters.keyWord.replace(/\s/g,""))' @keyup.enter.native='getStudentLearningAccountListPage'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        v-loading='listLoading'
        :data="tableData"
        highlight-current-row
        @current-change='selectCurrentRow'
        style="width: 100%">
            <el-table-column type="index" width="55" label='序号' align='center' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="Name" width="" label='姓名' align='center'></el-table-column> -->
            <el-table-column prop="PhoneNumber" width="" label='手机号' align='center'></el-table-column>
            <el-table-column prop="RequestSource" width="" label='数据来源' align='center'></el-table-column>
            <el-table-column prop="IsEnable	" width="" label='启用标识' align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable == true?"success":"primary"'>{{scope.row.IsEnable == true?'启用':'禁用'}}</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="CreateUserID" width="" label='创建人' align='center'></el-table-column> -->
            <el-table-column prop="CreateTime" width="" label='创建时间' align='center' :formatter="formatCreateTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs'
import {getStudentLearningAccountListPage,enableOrDisableStudentLearningAccount} from '@/api/api'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList: [],
            filters:{
                keyWord:''
            },
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            listLoading:false,
            tableData:[],
            currentRow:{}
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 获取列表数据
        getData(){
            var params = {
                keyWord:this.filters.keyWord,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            this.listLoading = true
            getStudentLearningAccountListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        },
        // 启用/禁用
        enableOrDisableStudentLearningAccount(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要操作的一行数据')
            }else{
                this.$confirm('确定启用/禁用？',"提示",{}).then(() => {
                    var params = {
                        id:row.ID,
                        IsEnabled:!row.IsEnable
                    }
                    enableOrDisableStudentLearningAccount(Qs.stringify(params)).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                })
            }           
        },
        // 查询
        getStudentLearningAccountListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>